const skillsSeries = [
    {marker: {symbol: 'url(javascript.svg)'}, data: [{name: 'JavaScript', value: 90}]},
    {marker: {symbol: 'url(typescript.svg)'}, data: [{name: 'TypeScript', value: 50}]},
    {marker: {symbol: 'url(html5.svg)'}, data: [{name: 'HTML5', value: 80}]},
    {marker: {symbol: 'url(css3.svg)'}, data: [{name: 'CSS3', value: 80}]},
    {marker: {symbol: 'url(sql.svg)'}, data: [{name: 'SQL', value: 70}]},
    {marker: {symbol: 'url(react.svg)'}, data: [{name: 'React', value: 90}]},
    {marker: {symbol: 'url(jquery.svg)'}, data: [{name: 'jQuery', value: 70}]},
    {marker: {symbol: 'url(redux.svg)'}, data: [{name: 'Redux', value: 70}]},
    {marker: {symbol: 'url(highcharts.svg)'}, data: [{name: 'Highcharts', value: 80}]},
    {marker: {symbol: 'url(sass.svg)'}, data: [{name: 'Sass', value: 60}]},
    {marker: {symbol: 'url(styledcomponents.svg)'}, data: [{name: 'Styled-Components', value: 70}]},
    {marker: {symbol: 'url(bootstrap.svg)'}, data: [{name: 'Bootstrap', value: 70}]},
    {marker: {symbol: 'url(node.svg)'}, data: [{name: 'Node', value: 90}]},
    {marker: {symbol: 'url(express.svg)'}, data: [{name: 'Express', value: 90}]},
    {marker: {symbol: 'url(sequelize.svg)'}, data: [{name: 'Sequelize', value: 80}]},
    {marker: {symbol: 'url(mongodb.svg)'}, data: [{name: 'MongoDB', value: 60}]},
    {marker: {symbol: 'url(mocha.svg)'}, data: [{name: 'Mocha', value: 60}]},
    {marker: {symbol: 'url(jest.svg)'}, data: [{name: 'Jest', value: 60}]},
    {marker: {symbol: 'url(git.svg)'}, data: [{name: 'Git', value: 80}]},
    {marker: {symbol: 'url(webpack.svg)'}, data: [{name: 'Webpack', value: 80}]},
    {marker: {symbol: 'url(rest.svg)'}, data: [{name: 'RESTful APIs', value: 90}]},
    {marker: {symbol: 'url(chrome.svg)'}, data: [{name: 'Chrome APIs', value: 60}]},
    {marker: {symbol: 'url(gatsby.svg)'}, data: [{name: 'Gatsby', value: 60}]}
  ];

export default skillsSeries;